import React from "react"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { Subpage } from "../../components/Subpage"
import { Helmet } from "react-helmet";

const header = "Products"
const description =
  "We make many different types of products. Below are some examples of what we do. Let us know what products you need and we will make them for you."

export default function Products({ data }) {
  const products = data.products.nodes
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakDevices - Products</title>
        <meta
          name="description"
          content="Oakdevices - transform Your Business Into The Company Of The Future"
        />
      </Helmet>
      <Subpage header={header} description={description} elements={products} />
    </Layout>
  )
}

export const query = graphql`
  query ProductsPage {
    products: allContentfulProduct(sort: { fields: createdAt, order: DESC }) {
      nodes {
        id
        title
        tags
        slug
        category
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
`
