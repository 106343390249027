import styled from "styled-components"

export const Container = styled.div`
  position: relative;
`
export const Content = styled.div`
  margin: 50px auto 0;
  padding: 150px 10vw 0;
  max-width: calc(1400px + 10vw);
  @media (max-width: 890px) {
    margin: 50px auto 0;
    padding: 0 8vw;
  }
`
export const Top = styled.div``
export const Background = styled.div`
  width: 100%;
  position: absolute;
  top: -30px;
  right: 0;
  z-index: -1;
`
export const Header = styled.h2`
  margin-bottom: 30px;
  @media (max-width: 890px) {
    margin-bottom: 20px;
  }
`
export const Description = styled.p`
  width: 45vw;
  margin-bottom: 180px;
  @media (max-width: 890px) {
    width: 100%;
    margin: 20px 0 90px;
  }
`
export const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  max-width: 670px;
  margin: 0 auto;
  @media (max-width: 890px) {
    gap: 8px;
  }
`
export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin: 140px auto 0;
  max-width: 1200px;
  @media (max-width: 890px) {
    margin: 50px auto 0;
  }
`

export const Text = styled.div`
  position: relative;
  z-index: -1;
`

export const Star1 = styled.img`
  position: absolute;
  bottom: -48px;
  left: 10px;
`

export const Star2 = styled.img`
  position: absolute;
  height: 30px;
  top: -88px;
  left: 66%;
  @media (max-width: 890px) {
    margin: 50px auto 0;
    padding: 0 8vw;
  }
`
