import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Tag } from "../Tag"
import { SectionElement } from "../SectionElement"
import star1 from "../../images/star-full.svg"
import star2 from "../../images/star-empty.png"
import {
  Container,
  Top,
  Background,
  Header,
  Description,
  Tags,
  Bottom,
  Content,
  Star1,
  Star2,
  Text,
} from "./style"

export const Subpage = ({ header, description, elements }) => {
  return (
    <Container>
      <Background>
        <StaticImage
          src="../../images/generated-page-background.png"
          alt="lines"
          placeholder="blurred"
          objectFit="fill"
          style={{
            width: "100%",
          }}
        />
      </Background>
      <Content>
        <Top>
          <Text>
            <Star1 src={star1} alt="star" />
            <Star2 src={star2} alt="star" />
            <Header>{header}</Header>
            <Description>{description}</Description>
          </Text>
          <Tags>
            <Tag dark text="Socket.Io" />
            <Tag dark text="Mosca MQTT" />
            <Tag dark text="Node.js" />
            <Tag dark text="MongoDB" />
            <Tag dark text="React.js" />
            <Tag dark text="Socket.Io" />
            <Tag dark text="Android" />
            <Tag dark text="Socket.Io" />
            <Tag dark text="Socket.Io" />
            <Tag dark text="Socket.Io" />
          </Tags>
        </Top>
        <Bottom>
          {elements.map(element => (
            <SectionElement isSubpage key={element.id} product={element} />
          ))}
        </Bottom>
      </Content>
    </Container>
  )
}
